import { gql } from "@apollo/client";

export const SEE_TOTAL_SCAR_COUNT = gql`
  query seeTotalScarCount {
    seeTotalScarCount {
      tokenExpired
      result
      totalScarCount
    }
  }
`;

export const SEE_BY_SCAR_STATUS_COUNT = gql`
  query seeByScarStatusCount {
    seeByScarStatusCount {
      tokenExpired
      result
      byStatusCount {
        totalCount
        inProgressCount
        actionRequireCount
        confirmRequireCount
        rejectCount
        finalCount
      }
    }
  }
`;

export const SEE_BY_SCAR_PROCESS_COUNT = gql`
  query seeByScarProcessCount {
    seeByScarProcessCount {
      tokenExpired
      result
      byProcessScarCount {
        completeCount
        complete
        inProgress
        delay
      }
    }
  }
`;

export const SEE_BY_CHECK_LIST_COUNT = gql`
  query seeByCheckListCount {
    seeByCheckListCount {
      tokenExpired
      result
      byCheckListCount {
        inProgressCount
        completeCount
        complete
        inProgress
        delay
      }
    }
  }
`;

export const SEE_BY_YEAR_SCAR_COUNT = gql`
  query seeByYearScarCount {
    seeByYearScarCount {
      tokenExpired
      result
      byYearScarCount {
        year
        scarCount
      }
    }
  }
`;

export const SEE_BY_PROJECT_SCAR_COUNT = gql`
  query seeByProjectScarCount {
    seeByProjectScarCount {
      tokenExpired
      result
      projectScarInfo {
        prjTitle
        scarCount
        completeCount
        inProgressCount
        delayCount
        avgDay
      }
    }
  }
`;

export const SEE_BY_YEAR_PROJECT_COUNT = gql`
  query seeByYearProjectCount {
    seeByYearProjectCount {
      tokenExpired
      result
      byYearProjectCountInfo {
        year
        projectCount
      }
    }
  }
`;

export const SEE_PROJECT_COMPLETE_RATIO = gql`
  query seeProjectCompleteRatio {
    seeProjectCompleteRatio {
      tokenExpired
      result
      byProjectStatusRatio {
        completeRatio
        totalCount
        completeCount
        inProgressCount
      }
    }
  }
`;

export const SEE_CS_COUNT = gql`
  query seeCsCount {
    seeCsCount {
      tokenExpired
      result
      csCountInfo {
        unProcessSignupCount
        oneOnOneCount
      }
    }
  }
`;

export const SEE_REFRESH_TIME = gql`
  query seeRefreshTime {
    seeRefreshTime {
      tokenExpired
      result
      time
    }
  }
`;

// ----------

export const SEE_GROUP_AUTH_CODE = gql`
  query seeGroupAuthCode {
    seeGroupAuthCode {
      tokenExpired
      result
      codeList {
        head
        local
        builder
        owner
      }
    }
  }
`;

export const SEE_PRJ_STATISTIC = gql`
  query seePrjStatistic {
    seePrjStatistic {
      tokenExpired
      result
      projectStatisticList {
        month
        count
      }
    }
  }
`;

export const SEE_SIGNUP_USER_STATISTIC = gql`
  query seeSignupUserStatistic {
    seeSignupUserStatistic {
      tokenExpired
      result
      signupStatistics {
        month
        count
      }
    }
  }
`;

export const SEE_USER_STATISTIC = gql`
  query seeUserStatistic {
    seeUserStatistic {
      tokenExpired
      result
      head
      local
      builder
      owner
      totalCount
    }
  }
`;

export const SEE_RECENTLY_ONE_LIST = gql`
  query seeRecentlyOneList {
    seeRecentlyOneList {
      tokenExpired
      result
      recentlyOneList {
        createdAt
        groupCompany
        writer
        question
      }
    }
  }
`;

export const SEE_RECENTLY_NOTICE = gql`
  query seeRecentlyNotice {
    seeRecentlyNotice {
      tokenExpired
      result
      recentlyNoticeList {
        noti_title
        noti_createdAt
      }
    }
  }
`;

export const SEE_AUTH_REQUEST_LIST = gql`
  query seeAuthRequestList {
    seeAuthRequestList {
      tokenExpired
      result
      userAuthRequestList {
        user_createdAt
        user_groupCompany
        user_name
        user_rank
        user_cellphone
        user_email
      }
    }
  }
`;
